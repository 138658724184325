// Sentry to be exported over for front and backend

import * as Sentry from "@sentry/nextjs"

import pjson from "./package.json"

const configureSentry = () => {
  if (process.env.CONTEXT !== "dev") {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.CONTEXT,
      attachStacktrace: true,
      sampleRate: 1.0,
      release: pjson.version,
      sendDefaultPii: false,
      tracesSampleRate: 0.0,
    })
  }
}

export default configureSentry
