import React, { useState } from "react"
import { useRouter } from "next/router"
import { signOut } from "next-auth/react"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Divider from "@mui/material/Divider"
import { colors } from "@parallel-domain/pd-theme"
import { ArrowSeparateVertical as SelectToggleIcon, User as AvatarIcon, Database as AdminIcon } from "iconoir-react"
import {
  LogOut as LogoutIcon,
  MessageText as FeedbackIcon,
  ProfileCircle as ProfileIcon,
  Neighbourhood as OrganizationsIcon,
} from "iconoir-react"

// import app components
import Feedback from "components/feedback"
import useCustomSession from "features/authentication/hooks/useSession"
import OrgSwitcher from "./OrgSwitcher"

const UserAvatar = () => {
  const [avatarMenu, setAvatarMenu] = useState<null | HTMLElement>(null)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false)
  const [orgSwitcherOpen, setOrgSwitcherOpen] = useState(false)

  const router = useRouter()

  const { data: session } = useCustomSession()

  const getName = () => {
    // Try to render first and last name
    if (session?.user?.first_name && session?.user?.last_name) {
      return `${session.user.first_name} ${session.user.last_name}`

      // If not available try to render only first name
    } else if (session?.user?.first_name) {
      return session.user.first_name

      // And if that's not available try to render email
    } else if (session?.user?.email) {
      return session.user.email
    }

    return ""
  }

  const handleCloseFeedbackDialog = () => {
    setFeedbackFormOpen(false)
  }

  const handleLogout = async () => {
    setLogoutLoading(true)
    signOut({ callbackUrl: "/auth/login" })
  }

  const handleOpenFeedbackDialog = () => {
    setFeedbackFormOpen(true)
  }

  const handleOpenAdmin = () => {
    window.open(`${process.env.NEXT_PUBLIC_PD_CMS}/login?jwt=${session.refreshToken}`, "_blank")
    setAvatarMenu(null)
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: 300,
          height: 42,
          cursor: "pointer",
          transition: "ease all 0.2s",
          background: colors.secondary[800],
          py: 0.5,
          px: 0.5,
          borderRadius: 2,
          border: 1,
          borderColor: colors.border[800],
          display: "flex",
          alignItems: "center",

          "&:hover": {
            background: colors.secondary[700],
          },
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => setAvatarMenu(e.currentTarget)}
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Avatar sx={{ width: 26, height: 26, background: "transparent" }}>
            <AvatarIcon style={{ color: colors.icons[700] }} />
          </Avatar>

          <Stack spacing={0} sx={{ display: { xs: "none", md: "initial" } }}>
            <Typography variant="body2" noWrap sx={{ width: "100%", fontSize: 13, lineHeight: "16px" }}>
              {getName()}
            </Typography>

            {session?.user?.activeOrganization && (
              <Typography variant="body2" noWrap sx={{ width: "100%", color: colors.text[700], fontSize: 12 }}>
                @{session?.user?.activeOrganization.name}
              </Typography>
            )}
          </Stack>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <SelectToggleIcon width={18} height={18} style={{ color: colors.icons[400] }} />
          </Box>
        </Stack>
      </Box>

      <Menu
        anchorEl={avatarMenu}
        open={Boolean(avatarMenu)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ svg: { color: colors.icons[100] }, ".MuiList-root": { width: 200 }, ".MuiMenuItem-root": { px: 2 } }}
        onClose={() => setAvatarMenu(null)}
      >
        <Divider />

        <MenuItem
          onClick={() => {
            router.push("/settings/profile")
            setAvatarMenu(null)
          }}
        >
          <ListItemIcon>
            <ProfileIcon width={18} height={18} />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ fontSize: 14 }}>
            Profile
          </ListItemText>
        </MenuItem>

        {session?.user?.organizations && session?.user?.organizations.length > 1 && (
          <MenuItem
            onClick={() => {
              setAvatarMenu(null)
              setOrgSwitcherOpen(true)
            }}
          >
            <ListItemIcon>
              <OrganizationsIcon width={18} height={18} />
            </ListItemIcon>
            <ListItemText disableTypography sx={{ fontSize: 14 }}>
              Switch Organization
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleOpenFeedbackDialog()
            setAvatarMenu(null)
          }}
        >
          <ListItemIcon>
            <FeedbackIcon width={18} height={18} />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ fontSize: 14 }}>
            Feedback
          </ListItemText>
        </MenuItem>

        {session.user.is_cms_admin && (
          <MenuItem onClick={handleOpenAdmin} sx={{ color: colors.review[900] }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <AdminIcon width={18} height={18} style={{ color: "inherit" }} />
            </ListItemIcon>
            <ListItemText disableTypography sx={{ fontSize: 14 }}>
              CMS Admin
            </ListItemText>
          </MenuItem>
        )}

        <Divider sx={{ my: "0 !important" }} />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            {logoutLoading ? <CircularProgress size={18} /> : <LogoutIcon width={18} height={18} />}
          </ListItemIcon>
          <ListItemText disableTypography sx={{ fontSize: 14 }}>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu>

      <Feedback open={feedbackFormOpen} onClose={handleCloseFeedbackDialog} />

      <OrgSwitcher open={orgSwitcherOpen} onClose={() => setOrgSwitcherOpen(false)} />
    </>
  )
}

export default UserAvatar
