import create from "zustand"

export type SidebarAuth = {
  open?: boolean
}

type Store = {
  menu: boolean
  setMenu: (open: boolean) => void
}

const useLayout = create<Store>((set) => ({
  menu: true,
  setMenu: (open) =>
    set(() => ({
      menu: open,
    })),
}))

export default useLayout
