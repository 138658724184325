import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Link from "next/link"
import { colors } from "@parallel-domain/pd-theme"
import type { NavItem } from "@parallel-domain/pd-theme/types"
import { useRouter } from "next/router"

// Recursive component to render each NavItem
const NavItemComponent: React.FC<{ item: NavItem }> = ({ item }) => {
  const [open, setOpen] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (item.children) {
      item.children.forEach((item) => {
        if (item.href && router.asPath.includes(item.href)) {
          setOpen(true)
          return
        }
      })
    }
  }, [])

  const handleClick = (e: React.MouseEvent) => {
    if (Array.isArray(item.children) && item.children.length) {
      e.preventDefault() // Prevent navigation for parent items
      setOpen(!open)
    } else if (item.onClick) {
      item.onClick()
    }
  }

  const renderListItem = (itemContent: JSX.Element) => {
    if (item.href) {
      return (
        <Link href={item.href} style={{ textDecoration: "none", color: "inherit" }}>
          {itemContent}
        </Link>
      )
    }
    return itemContent
  }

  const listItem = (
    <ListItemButton selected={item.active} onClick={handleClick}>
      <ListItemIcon
        sx={{
          minWidth: "30px",
          color: colors.icons[100],
          display: "flex",
          justifyContent: "center",
          mr: 1,
          svg: { width: 16, height: 16 },
        }}
      >
        {item.icon || (
          <Box sx={{ width: 6, height: 6, borderRadius: "50%", border: 1, borderColor: colors.border[100] }} />
        )}
      </ListItemIcon>
      <ListItemText primary={item.label} disableTypography sx={{ fontSize: 14 }} />
      {item.children && item.children.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
    </ListItemButton>
  )

  return (
    <>
      {renderListItem(listItem)}

      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((childItem: NavItem, index: number) => (
              <NavItemComponent key={index} item={childItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

// The SidebarMenu component
const SidebarMenu: React.FC<{ items: NavItem[] }> = ({ items }) => {
  return (
    <List>
      {items.map((item, index) => (
        <NavItemComponent key={index} item={item} />
      ))}
    </List>
  )
}

export default SidebarMenu
