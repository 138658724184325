import { SessionProvider } from "next-auth/react"
import type { AppProps } from "next/app"
import type { Session } from "next-auth"
import type { JWT } from "next-auth/jwt"
import { CacheProvider, EmotionCache } from "@emotion/react"
import { Theme as ThemeProvider } from "@parallel-domain/pd-theme"
import { ToastContainer } from "react-toastify"
import { GoogleAnalytics } from "nextjs-google-analytics"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// import app components
import PageWrapper from "components/pageWrapper"
import createEmotionCache from "config/createEmotionCache"

// Create a client
const queryClient = new QueryClient()

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache: EmotionCache = createEmotionCache()

const App = ({
  Component,
  pageProps: { session, ...pageProps },
  emotionCache = clientSideEmotionCache,
}: AppProps & {
  Component: { auth: boolean; showNavbar?: boolean; getLayout: (page: React.ReactElement) => React.ReactElement }
  pageProps: { session: JWT }
  emotionCache: EmotionCache
}) => {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <GoogleAnalytics trackPageViews={{ ignoreHashChange: true }} />

      <CacheProvider value={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <ToastContainer position="bottom-right" newestOnTop />
            <SessionProvider session={session as Session} refetchOnWindowFocus={false}>
              <PageWrapper showNavbar={Component.showNavbar} isProtected={Component.auth}>
                {getLayout(<Component {...pageProps} />)}
              </PageWrapper>
            </SessionProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </CacheProvider>
    </>
  )
}

export default App
