const formatVersion = (version: string, includeVersionPrefix = false, nullifyMinorRelease = false): string => {
  // Extract the MAJOR.MINOR.PATCH version using a regex pattern
  const matches = version.match(/v?(\d+\.\d+\.\d+)/)

  if (!matches) {
    return ""
  }

  let result = `${matches[1]}`

  if (nullifyMinorRelease) {
    // Split the version into MAJOR, MINOR, and PATCH components
    const parts = result.split(".")
    // Replace the PATCH component with '0'
    parts[2] = "0"
    // Reconstruct the version string
    result = parts.join(".")
  }

  if (includeVersionPrefix) {
    return `v${result}`
  }

  return result
}

export default formatVersion
