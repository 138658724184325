import NextLink from "next/link"
import { Box, Link, Typography, Tooltip, Stack } from "@mui/material"
import { ArrowRight as ArrowRightIcon, HelpCircle as HelpIcon } from "iconoir-react"
import { colors } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

import type { SearchResult } from "./types"

const ListItem = (props: SearchResult & { target: string; onClick: (slug: string) => void }) => {
  const { pageTitle, slug, anchor = "", sectionHeader, sectionTitle, sectionBody, target, onClick } = props

  let title: string = sectionHeader || sectionTitle

  if (pageTitle !== title) {
    title = `${pageTitle} | ${title}`
  }

  return (
    <Link
      component={NextLink}
      href={`${process.env.NEXT_PUBLIC_WEBAPP}${slug}${anchor}`}
      target={target}
      underline="none"
      scroll={false}
      sx={{
        position: "relative",
        py: 2,
        pl: 2,
        pr: "66px",
        background: colors.secondary[800],
        color: colors.text[100],
        borderRadius: "1rem",
        border: 1,
        borderColor: colors.border[700],
        "&:hover": {
          color: colors.primary[900],
          svg: {
            color: colors.primary[900],
          },
        },
      }}
      onClick={() => onClick(slug)}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color="inherit" variant="h5" noWrap>
          {parse(title)}
        </Typography>

        {sectionBody && (
          <Tooltip
            title={
              <Typography variant="body2" sx={{ em: { color: colors.primary[900] } }}>
                {parse(sectionBody)}
              </Typography>
            }
            arrow
            disableInteractive
          >
            <HelpIcon width={18} height={18} style={{ color: colors.icons[700], cursor: "help" }} />
          </Tooltip>
        )}
      </Stack>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderLeft: 1,
          borderColor: colors.border[700],
          color: colors.border[700],
        }}
      >
        <ArrowRightIcon style={{ fontSize: "0.8rem" }} />
      </Box>
    </Link>
  )
}

export default ListItem
