import { useEffect } from "react"
import { useRouter } from "next/router"

// import app components
import useReleases from "../store/useReleases"
import { SAMPLE_RELEASE_VERSION } from "../constants"
import useCustomSession from "features/authentication/hooks/useSession"
import formatVersion from "../utils/formatVersion"

const useReleasesQuery = () => {
  const { data: session } = useCustomSession()

  const router = useRouter()

  const setActiveRelease = useReleases((state) => state.setActiveRelease)

  // Set initial release version
  useEffect(() => {
    // Wait for session to load
    if (!session) return

    // Assign default version if user is not part of an org or org is not a step customer or has no releases assigned
    if (
      !session?.user?.activeOrganization?.slug ||
      !session?.user?.activeOrganization?.capabilities.includes("step") ||
      session.user.activeOrganization?.releases.length === 0 ||
      session?.error
    ) {
      return setActiveRelease(SAMPLE_RELEASE_VERSION)
    }

    let version = ""

    if (router.query.version) {
      version = router.query.version as string // Get version from query parameter
    } else if (window.localStorage.getItem("release")) {
      version = window.localStorage.getItem("release") // Or alternatively from local storage
    }

    // If version doesn't exists or user doesn't have access to it, we'll grab the first (= highest) version from the releases list
    if (
      !version ||
      !session.user.activeOrganization?.releases.find(
        (release) => formatVersion(release, true) === version || release === version
      )
    ) {
      version = session.user.activeOrganization.releases[0]
    }

    setActiveRelease(version)
  }, [router.query.version, session?.user?.activeOrganization])
}

export default useReleasesQuery
