import React, { useEffect, useState, useCallback, useRef } from "react"
import ReactDOM from "react-dom"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import Pagination from "@mui/material/Pagination"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { Input, colors, Typography, Paper } from "@parallel-domain/pd-theme"
import { Search as SearchIcon, Xmark as CancelIcon } from "iconoir-react"
import debounce from "lodash/debounce"
import axios, { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import { event } from "nextjs-google-analytics"
import { useQuery } from "@tanstack/react-query"

// import app components
import useLayout from "../../store/useLayout"
import useReleases from "features/releases/store/useReleases"
import useCustomSession from "features/authentication/hooks/useSession"
import getMaps from "features/maps/api/getMaps"
import ListItem from "./ListItem"
import type { SearchResult } from "./types"
import type { Map } from "features/maps/types"

type FloatingSearchInputProps = {
  anchorEl: null | HTMLElement
  inputPos: { top: number; left: number; width: number; height: number }
  onClose: () => void
}

const FloatingSearchInput = (props: FloatingSearchInputProps) => {
  const { anchorEl, inputPos, onClose } = props

  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<SearchResult[] | null>(null)
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState("")

  const activeRelease = useReleases((state) => state.activeRelease)

  const { data: session } = useCustomSession()

  const rowsPerPage = 5

  const query = useQuery({
    queryKey: ["locations", activeRelease],
    queryFn: () => getMaps(activeRelease),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!activeRelease,
  })

  useEffect(() => {
    debouncedOnChange(search)
  }, [search, tab])

  useEffect(() => {
    setResults(null)
    setLoading(!!search)
    setPage(1)
  }, [tab])

  useEffect(() => {
    setTab(session?.user?.activeOrganization ? "docs" : "datasets")
  }, [session?.user?.activeOrganization])

  const handlePageChange = (e: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const handleItemClick = (slug: string) => {
    event("docs_search", {
      type: "click",
      search_query: search,
      search_id: `${session?.user.id}`,
      page_slug: slug,
    })

    handleClose()
  }

  const handleSearch = async (search: string) => {
    if (!search) {
      setResults(null)
      return
    }

    setLoading(true)

    switch (tab) {
      case "docs":
        try {
          const response: AxiosResponse<SearchResult[]> = await axios.post("/api/content/searchDocs", {
            search,
            version: activeRelease,
          })
          setResults(response.data)
          setPage(1)

          event("docs_search", {
            type: "search",
            search_query: search,
            search_id: `${session?.user.id}`,
            page_slug: "",
          })
        } catch (e) {
          toast.error("Something went wrong")
        } finally {
          setLoading(false)
        }
        break

      case "datasets":
        try {
          const response: AxiosResponse<SearchResult[]> = await axios.post("/api/content/searchDatasets", {
            search,
          })
          setResults(response.data)
          setPage(1)
        } catch (e) {
          toast.error("Something went wrong")
        } finally {
          setLoading(false)
        }
        break

      case "locations":
        try {
          const response: AxiosResponse<SearchResult[]> = await axios.post("/api/content/searchLocations", {
            search,
            version: activeRelease,
          })
          setResults(response.data)
          setPage(1)
        } catch (e) {
          toast.error("Something went wrong")
        } finally {
          setLoading(false)
        }
        break
    }
  }

  // Use debounce function to update data for performance improvement
  const debouncedOnChange = useCallback(
    debounce((search: string) => handleSearch(search), 500),
    [activeRelease, tab]
  )

  const handleClose = () => {
    onClose()
    setResults(null)
    setSearch("")
    setPage(1)
  }

  const handleChangeTab = (newTab: string) => {
    setTab(newTab)
  }

  const renderSearchResults = () => {
    if (!results) return

    return (
      <>
        {results
          .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
          .map((o: SearchResult, i: number) => (
            <ListItem key={i} {...o} target={tab === "datasets" ? "_blank" : "_self"} onClick={handleItemClick} />
          ))}

        {results.length > 5 && (
          <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
            <Pagination page={page} count={Math.ceil(results.length / rowsPerPage)} onChange={handlePageChange} />
          </Box>
        )}
      </>
    )
  }

  const renderNoResults = () => {
    return (
      <Box sx={{ py: 10, display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">No search results, please try again!</Typography>
      </Box>
    )
  }

  const renderPlaceholder = () => {
    let results

    switch (tab) {
      case "docs":
        results = (
          <>
            {[
              {
                pageTitle: "Set Up & First Connection",
                slug: `/docs/${activeRelease}/set-up-first-connection`,
                sectionTitle: "Set Up & First Connection",
                sectionBody:
                  "Welcome to the start of your Data Lab journey! In this tutorial, we'll walk you through the setup and connection steps required for you to interact with Data Lab cloud instances.",
              },
              {
                pageTitle: "Anatomy of a Data Lab Script",
                slug: `/docs/${activeRelease}/anatomy-of-a-data-lab-script`,
                sectionTitle: "Anatomy of a Data Lab Script",
                sectionBody:
                  "In this section, we will go into a detailed discussion of the core components of a Data Lab script. By the end of this chapter, you will understand the foundations of how a Data Lab script is built.",
              },
              {
                pageTitle: "Sensor Rigs",
                slug: `/docs/${activeRelease}/sensor-rigs`,
                sectionTitle: "Sensor Rigs",
                sectionBody:
                  "In this Chapter of the User Guide, we will cover the steps you can take to replicate camera sensors. Currently, Data Lab supports the camera sensor type, with lidar and radar support planned for Q4 2023.",
              },
            ].map((o: SearchResult, i: number) => (
              <ListItem key={i} {...o} target="_self" onClick={handleClose} />
            ))}
          </>
        )
        break

      case "datasets":
        results = (
          <>
            {[
              {
                pageTitle: "San Francisco",
                slug: "/datasets/public/san-francisco-scene_a113",
                sectionTitle: "San Francisco",
              },
              {
                pageTitle: "Japanese Highways",
                slug: "/datasets/public/japanese-highways-dataset_3849",
                sectionTitle: "Japanese Highways",
              },
              {
                pageTitle: "Construction Sites",
                slug: "/datasets/public/construction-sites-dataset_1128",
                sectionTitle: "Construction Sites",
              },
            ].map((o: SearchResult, i: number) => (
              <ListItem key={i} {...o} target="_blank" onClick={handleClose} />
            ))}
          </>
        )

        break

      case "locations":
        results = (
          <>
            {query.data &&
              query.data.locations.map((o: Map, i: number) => {
                return (
                  <ListItem
                    key={i}
                    pageTitle={o.name}
                    slug={`/explore/${activeRelease}/locations/${o.name}`}
                    sectionTitle={o.name}
                    sectionBody=""
                    target="_blank"
                    onClick={handleClose}
                  />
                )
              })}
          </>
        )

        break
    }

    return (
      <Stack spacing={1}>
        <Typography variant="caption" color="text.secondary">
          Examples
        </Typography>
        {results}
      </Stack>
    )
  }

  if (!anchorEl) return null

  return ReactDOM.createPortal(
    <>
      <Box
        sx={{
          position: "fixed",
          top: `${inputPos.top}px`,
          left: `${inputPos.left}px`,
          width: `${inputPos.width}px`,
          zIndex: 1400,
        }}
      >
        <Input
          autoFocus
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          placeholder="Search..."
          startIcon={<SearchIcon width={18} height={18} style={{ color: colors.icons[700] }} />}
          {...(search && {
            endIcons: [
              {
                icon: <CancelIcon />,
                onClick: () => setSearch(""),
              },
            ],
          })}
          sx={{
            height: 42,
            ".MuiInput-root": {
              height: 42,
              width: "100%",
              minHeight: "unset",
              py: 0.5,
              background: colors.secondary[700],
              border: 1,
              borderColor: colors.border[800],
            },
          }}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 1200,
        }}
        onClick={handleClose}
      />

      <Box
        sx={{
          position: "fixed",
          zIndex: 1300,
          width: { xs: "calc(100% - 20px)", md: inputPos.width },
          left: { xs: 10, md: inputPos.left },
          top: inputPos.top + inputPos.height + 10,
        }}
      >
        <Paper sx={{ background: colors.secondary[700] }}>
          <Stack spacing={3}>
            <Tabs
              value={tab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event: React.SyntheticEvent, newValue: string) => handleChangeTab(newValue)}
            >
              {session?.user?.activeOrganization && <Tab label="Documentation" value="docs" />}
              <Tab label="Datasets" value="datasets" />
              {session?.user?.activeOrganization && <Tab label="Locations" value="locations" />}
            </Tabs>

            <Stack spacing={1}>
              {search ? (
                <>
                  {loading ? (
                    <>
                      <Skeleton variant="rounded" width="100%" height={56} sx={{ borderRadius: 4 }} />
                      <Skeleton variant="rounded" width="100%" height={56} sx={{ borderRadius: 4 }} />
                      <Skeleton variant="rounded" width="100%" height={56} sx={{ borderRadius: 4 }} />
                    </>
                  ) : results && results.length > 0 ? (
                    renderSearchResults()
                  ) : (
                    renderNoResults()
                  )}
                </>
              ) : (
                renderPlaceholder()
              )}
            </Stack>

            {tab === "docs" && (
              <Typography variant="body2" sx={{ color: colors.text[700] }}>
                Please note: The search feature is currently not available for the &quot;PD SDK Reference&quot; section
                of our documentation.
              </Typography>
            )}
          </Stack>
        </Paper>
      </Box>
    </>,
    document.body
  )
}

const Search = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [inputPos, setInputPos] = useState({ top: 0, left: 0, width: 0, height: 0 })

  const inputRef = useRef<null | HTMLElement>(null)

  const menu = useLayout((state) => state.menu)

  useEffect(() => {
    const updateInputPos = () => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect()
        setInputPos({ top: rect.top, left: rect.left, width: rect.width, height: rect.height })
      }
    }

    // Wait for drawer animation to finish
    setTimeout(() => {
      updateInputPos()
    }, 200)

    // Add resize listener to update input position on window resize
    window.addEventListener("resize", updateInputPos)

    return () => window.removeEventListener("resize", updateInputPos)
  }, [inputRef, menu])

  return (
    <>
      <FloatingSearchInput anchorEl={anchorEl} inputPos={inputPos} onClose={() => setAnchorEl(null)} />

      <Box ref={inputRef} onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}>
        <Input
          placeholder="Search..."
          startIcon={<SearchIcon width={18} height={18} style={{ color: colors.icons[700] }} />}
          sx={{
            display: "flex",
            height: 42,
            ".MuiInput-root": {
              width: { xs: "100%", md: 350, lg: 400, xl: 500 },
              flex: 1,
              minHeight: "unset",
              py: 0.5,
              background: colors.secondary[800],
              border: 1,
              borderColor: colors.border[800],
            },
          }}
        />
      </Box>
    </>
  )
}

export default Search
