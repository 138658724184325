export const SAMPLE_RELEASE_VERSION = "v2.7.0"

export const SAMPLE_RELEASE = {
  ig_version: "",
  le_version: "",
  sim_version: "",
  name: SAMPLE_RELEASE_VERSION,
  levels: [
    "A2_BurnsPark",
    "A2_ForestHillCemetery",
    "A2_Kerrytown",
    "FICT_Racetrack_1000_1",
    "SC_Highlands",
    "SC_MathildaAndSunnyvaleSaratoga_large",
    "SC_W8thAndOrchard",
    "SF_6thAndMission_medium",
    "SF_GrantAndCalifornia",
    "SF_JacksonAndKearny",
    "SF_LafayettePark",
    "SF_VanNessAveAndTurkSt",
    "SJ_237AndGreatAmerica",
    "SJ_237AndNorth1st",
    "SJ_237AndZanker",
    "SJ_680MissionPass",
    "SJ_EssexAndBradford",
    "SJ_KettmanAndOrinda_aus",
  ],
}
