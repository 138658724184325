import create from "zustand"
import produce from "immer"

type Store = {
  activeRelease: string
  setActiveRelease: (release: string) => void
}

const useReleases = create<Store>((set) => ({
  activeRelease: "",
  setActiveRelease: (release) => {
    set(
      produce((state: Store) => {
        state.activeRelease = release
      })
    )

    window.localStorage.setItem("release", release)
  },
}))

export default useReleases
