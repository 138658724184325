import { useState } from "react"
import axios from "axios"
import { Alert, AlertTitle, Stack } from "@mui/material"
import { Button, Dialog, Input } from "@parallel-domain/pd-theme"
import { useForm, Controller, SubmitHandler } from "react-hook-form"

type Props = {
  open: boolean
  onClose: () => void
}

type FormValues = {
  message: string
}

const Feedback = (props: Props) => {
  const { open, onClose } = props

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      message: "",
    },
  })

  const handleClose = () => {
    onClose()

    // Use timeout to prevent jumpiness
    setTimeout(() => {
      setStatus("")
      setLoading(false)
      reset()
    }, 200)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = async (formData) => {
    const { message } = formData

    try {
      setLoading(true)
      await axios.post("/api/feedback", { message, url: window.location.href })
      setStatus("success")
    } catch (e) {
      setStatus("error")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog title="Feedback" open={open} onClose={handleClose}>
      {status === "success" ? (
        <Alert severity="success">
          <AlertTitle>Feedback submitted successfully</AlertTitle>
          Thanks for contacting us! We will get back to you as soon as possible.
        </Alert>
      ) : (
        <form method="post" noValidate onSubmit={handleSubmit(handleSubmitForm)} style={{ marginBottom: 0 }}>
          <Stack spacing={3}>
            <Controller
              name="message"
              control={control}
              rules={{
                required: "Please enter a message",
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Input
                  placeholder="Message*"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? `${error.message}` : ""}
                  fullWidth
                  rows={6}
                  multiline
                  sx={{ ".MuiOutlinedInput-root": { p: 0 } }}
                />
              )}
            />

            {status === "error" && (
              <Alert severity="error" sx={{ background: "#d32f2f" }}>
                An error occurred while sending the information. Please try again later.
              </Alert>
            )}

            <Button type="submit" variant="outlined" color="primary" loading={loading} fullWidth>
              Submit
            </Button>
          </Stack>
        </form>
      )}
    </Dialog>
  )
}

export default Feedback
