// Directory of readme.com documentation`
export const README_BASE_DIRECTORY = "docs"
export const README_ENTRY_PAGE = "getting-started"

// Base urls to access PD SDK documentation
export const PD_SDK_BASE_URL = "https://parallel-domain.github.io/pd-sdk"
export const PD_SDK_INTERNAL_BASE_URL = "https://pd-sdk-documentation.netlify.app"
export const PD_SDK_BASE_DIRECTORY = "pd-sdk"
export const PD_SDK_VERSION_INDEX = {
  "mainline-snaptshot-20240202CL69406": "main",
  "v2.7.0": "releases-r2-7",
  "v2.7.1": "releases-r2-7",
  "v2.8.0": "releases-r2",
  "v3.3.0": "releases-r3-3",
  "v3.3.1": "releases-r3-3",
  "v3.3.2": "releases-r3-3",
  "v3.4.0": "releases-r3-4",
  "v3.5.0": "releases-r3-5",
  "v3.5.1": "releases-r3-5",
  "v3.5.2": "releases-r3-5",
  "v3.6.0": "releases-r3-6",
  "v3.7.0": "releases-r3-7",
} as { [key: string]: string }

export const DOC_NOT_FOUND = {
  title: "Page not found",
  content: "Please select another page via the sidebar menu.",
  nav: [],
}
