import { useEffect } from "react"
import create from "zustand"
import { getSession, useSession as useNextAuthSession } from "next-auth/react"
import { Session } from "next-auth"

type Store = {
  session: Session | null
  loading: boolean
  sessionFetchPromise: Promise<Session | null> | null
  setSessionFetchPromise: (promise: Promise<Session | null> | null) => void
  fetchSession: () => Promise<Session | null>
}

const useSessionStore = create<Store>((set, get) => ({
  session: null,
  loading: true,
  sessionFetchPromise: null,
  setSessionFetchPromise: (promise) => {
    set({ sessionFetchPromise: promise })
  },
  fetchSession: async () => {
    const currentSession = get().session
    const currentTime = Date.now()

    if (currentSession && currentTime < currentSession.accessTokenExpires - 1000 * 60) {
      // Session is valid, return it
      return currentSession
    }

    // If we're already fetching the session, return the existing promise
    if (get().sessionFetchPromise) {
      return get().sessionFetchPromise
    }

    // Otherwise, start fetching the session
    const promise = getSession().then((sessionData) => {
      set({ session: sessionData, loading: false })
      get().setSessionFetchPromise(null)
      return sessionData
    })

    get().setSessionFetchPromise(promise)

    return promise
  },
}))

const useCustomSession = () => {
  const { data: initialSession, update } = useNextAuthSession()

  const session = useSessionStore((state) => state.session)
  const fetchSession = useSessionStore((state) => state.fetchSession)

  // Determine which session to use based on the 'expires' property
  let data: Session | null
  if (session && initialSession) {
    data = session.expires > initialSession.expires ? session : initialSession
  } else {
    data = session || initialSession
  }

  const status = data ? "authenticated" : "loading"

  useEffect(() => {
    let interval: NodeJS.Timeout

    const checkSessionValidity = async () => {
      await fetchSession()
    }

    // Immediately invoke the function to check on mount
    checkSessionValidity().then(() => {
      // Set up an interval to check the session validity regularly
      interval = setInterval(checkSessionValidity, 30 * 1000) // Check every 30 seconds
    })

    // Clear the interval when the component unmounts or if the effect re-runs
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  return {
    data,
    status,
    update,
  }
}

export default useCustomSession
